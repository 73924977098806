import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalDataContext } from "../../../context/context";
import { FaCreditCard, FaEnvelope } from 'react-icons/fa'
import { FiMapPin, FiPhoneCall } from 'react-icons/fi'





function FooterNew1() {
    const { rpdata } = useContext(GlobalDataContext);
    // const yearsActual = new Date();

    const menu = [
        {
            name: "Home",
            link: "/",
        },
        {
            name: "About",
            link: "/about",
        },
        {
            name: "Services",
            link: "/services",
        },
        {
            name: "Gallery",
            link: "/gallery",
        },
        {
            name: "Contact",
            link: "/contact",
        },
    ];

    // agregar la pestaña de reviews al array de dbMenu

    const el = {
        name: `Reviews`,
        link: `/reviews`,
        child: false,
    }

    if (rpdata?.reviews?.activo && rpdata?.reviews?.isHomeOnly === false) {
        const num = menu.length - 1
        menu.splice(num, 0, el)
    }
    //  fin de agregar pestaña de reviews

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <>
            <section className="w-full h-auto lg:h-[80vh] overflow-visible lg:overflow-hidden bg-[#1e1e1e]">
                <div class="elfsight-app-02423e2c-91ab-4577-89e1-a55af1d9312c" data-elfsight-app-lazy></div>
                <div className="w-full h-full flex flex-col lg:flex-row items-center relative pt-14">
                    <div
                        className="block lg:hidden w-full relative h-[300px] overflow-hidden rounded-l-full border-l-[25px] border-t-[25px] border-b-[25px] border-[#1e1e1e8e]"
                        style={{
                            backgroundImage: `url("${rpdata?.stock?.[10]}")`,
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                        }}
                    >
                        <img
                            src={rpdata?.stock?.[10]}
                            width={200}
                            height={200}
                            alt="foto owner"
                            className="object-cover w-full h-full "
                        />
                        <div className="absolute top-[18%] left-[20%] z-20 text-white">
                            <img
                                src={rpdata?.dbPrincipal?.logo}
                                width={200}
                                height={200}
                                alt="logo"
                                className="object-cover w-[250px]"
                            />
                        </div>
                    </div>
                    <div className="lg:absolute top-10 left-[8%] text-white pt-5" onClick={goToTop}>
                        <ul className="pl-2 flex space-x-2" onClick={goToTop}>
                            {
                                menu.map((item, index) => {
                                    return (
                                        <li className="py-2" onClick={goToTop} key={index}>
                                            <Link to={item.link} className="">
                                                <span className="pl-2 font-semibold text-lg">{item.name}</span>
                                            </Link>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div >


                    <div className="w-[60%] hidden lg:flex justify-end h-[95%] rounded-l-full overflow-hidden rotate-[48deg] -mb-[28%] border-[50px] border-[#1e1e1e8e] relative object-fill"
                        style={{
                            backgroundImage: `url("${rpdata?.stock?.[10]}")`,
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                        }}
                    >
                        <img
                            src={rpdata?.stock?.[10]}
                            width={200}
                            height={200}
                            alt="foto owner"
                            className="object-fill w-full h-full"
                        />
                        <div className="absolute top-[18%] left-[12%] z-20 text-white -rotate-[48deg]">
                            <img
                                src={rpdata?.dbPrincipal?.logo}
                                width={200}
                                height={200}
                                alt="logo"
                                className="object-cover w-[350px]"
                            />
                        </div>
                    </div>

                    <div className="h-full w-full lg:w-[50%] flex flex-col gap-5 text-white px-5 lg:px-0 pt-5 -ml-0 md:-ml-10 mb-[95px]">
                        <div className="flex w-full lg:flex-row flex-col justify-start">
                            <section className="w-full py-1 px-5">
                                <h4 className="text-primary font-bold -ml-9">About Us</h4>
                                <p className="text-justify text-white pr-0 md:pr-24">{rpdata?.dbAbout?.[1].text.substring(0, 383)}</p>
                            </section>
                        </div>
                        <div className="flex w-full flex-col justify-start">
                            <h4 className="text-primary font-bold -ml-2 md:-ml-5 text-white">Contact</h4>
                            <div className="w-full flex flex-col md:flex-row">
                                <section className="pt-2 lg:pt-2 md:w-3/5 w-full flex flex-col justify-start text-start">
                                    <div className="w-full h-auto px-0 md:px-3 flex justify-start items-center space-x-2">
                                        <span className="text-white font-semibold text-[22px]">Emails</span>
                                    </div>
                                    <ul className="w-full px-0 md:px-4">
                                        {
                                            rpdata?.dbPrincipal?.emails?.map((item, index) => {
                                                return (
                                                    <li key={index} className="flex justify-start items-center space-x-2 py-1">
                                                        <a className="flex justify-start items-center space-x-2 py-1"
                                                            href={`mailto:${item.email}`}
                                                        >
                                                            <span className="py-2 px-2 border border-white rounded-full ">
                                                                <FaEnvelope className="text-[14px] text-primary text-white" />
                                                            </span>
                                                            <span className="text-[16px]">{item.email}</span>
                                                        </a>

                                                    </li>
                                                );
                                            })
                                        }
                                    </ul>
                                </section>
                                <section className="pt-2 lg:pt-2 md:w-2/5 w-full flex flex-col justify-start text-start">

                                    <div className="w-full h-auto px-0 md:px-3 flex justify-start items-center space-x-2">
                                        <span className="text-white font-semibold text-[22px]">Phones</span>
                                    </div>
                                    <ul className="w-full px-0 md:px-4">
                                        {
                                            rpdata?.dbPrincipal?.phones?.map((item, index) => {
                                                return (
                                                    <li key={index} className="flex justify-start items-center space-x-2 py-1">
                                                        <a className="flex justify-start items-center space-x-2 py-1"
                                                            href={`tel:+1${item.phone}`}
                                                        >
                                                            <span className="py-2 px-2 border border-white rounded-full ">
                                                                <FiPhoneCall className="text-[14px] text-primary text-white" />
                                                            </span>
                                                            <span className="text-[16px]">{item.phone}</span>
                                                        </a>
                                                    </li>
                                                );
                                            })
                                        }
                                    </ul>
                                </section>

                            </div>


                        </div>
                        <div className="flex w-full h-full items-end justify-end p-2">
                            <section className="w-full flex flex-col md:flex-row justify-start text-start border-t-2">
                                <div className="w-full md:w-auto flex items-center md:flex-row ">
                                    <FiMapPin size={32} className="text-primary text-white pr-1" />
                                    <p className=" font-bold text-white ">Locations:</p>
                                    <ul className="w-full flex py-2">
                                        {
                                            rpdata?.dbPrincipal?.location.slice(0, 2).map((item, index) => {
                                                return (
                                                    <li key={index} className="flex justify-start items-center space-x-1 py-1 px-1 ">
                                                        <span className="text-[14px] border-r pr-2">{item.address}</span>
                                                    </li>
                                                );
                                            })
                                        }
                                    </ul>

                                </div>
                                <div className="w-full md:w-[50%] flex flex-col md:flex-row items-start md:items-center justify-start">
                                    <div className="w-auto flex justify-center items-center">
                                        <FaCreditCard size={24} className="text-primary text-white pr-1" />

                                    </div>
                                    <ul className=" w-full flex items-center">

                                        <li className="flex justify-start items-center px-1">
                                            <span className="text-[15px]">:{rpdata?.dbPrincipal?.paymentMethod}</span>
                                        </li>


                                    </ul>

                                </div>
                            </section>

                        </div>


                    </div>
                </div>
            </section>
        </>
    );
}

export default FooterNew1;
